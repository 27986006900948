<template>
  <div id="Login">
    <v-container fluid class="d-flex justify-center align-center">
      <v-card class="mx-auto rounded" max-width="90%" width="450">
        <v-toolbar class="blue-grey darken-2 elevation-0" dark>
          <v-toolbar-title class="font-weight-black"
            >後台系統登入</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="px-6 pb-0">
          <v-form ref="LoginForm" v-model="valid" lazy-validation>
            <v-text-field
              v-model="account"
              name="account"
              :rules="rules"
              label="帳號"
              required
            ></v-text-field>

            <v-text-field
              v-model="password"
              name="password"
              :rules="rules"
              label="密碼"
              type="password"
              @keydown.enter="Validate"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-4 pb-4">
          <v-spacer></v-spacer>
          <v-btn color="blue-grey darken-2" dark @click="Validate">
            登入
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      valid: false,
      account: "",
      password: "",
      rules: [
        v => !!v || '此欄位不得留空',
      ],
    }
  },
  methods: {
    Validate() {
      this.$refs.LoginForm.validate()
      setTimeout(() => {
        if (this.valid) {
          this.Login()
        }
      }, 100);
    },
    async Login() {
      this.$store.commit("SetLoading", true)
      let response = await this.SendData("/admin/login", "post", { Account: this.account, Passwd: this.password })
      this.$store.commit("SetLoading", false)
      if (response != "error") {

        this.$cookie.set('account', JSON.stringify({
          account: response.Admin.Account,
          id: response.Admin.AdminID,
          name: response.Admin.Nickname,
          role: response.Admin.Role,
        }), { expires: '30m' });
        this.$cookie.set('user_token', response.Token, { expires: '30m' });
        this.$router.push("/")
      }
    }
  },
}
</script>